// src/IBMModel.js
import React from 'react';
import { useGLTF, Html, OrbitControls } from '@react-three/drei';  // Import OrbitControls
import LivePortfolio from './LivePortfolio';  // Import the new LivePortfolio

export default function IBMModel() {
  const { scene } = useGLTF('/ibm_model.gltf');
  
  return (
    <>
      <primitive object={scene} scale={0.5} position={[0, -6, 0]} />
      
      {/* Position the 2D Live Portfolio within the 3D Model's screen */}
      <Html 
        position={[1, 3, 0.1]}   // Adjust these values to place the overlay on the screen
        rotation={[0, 1.5, 0]}  
        // Rotation adjusted for the screen alignment
        transform
      >
        {/* Render the live portfolio inside the IBM screen */}
        <LivePortfolio />
      </Html>
      {/* OrbitControls with all interactions disabled */}
      
    </>
  );
}
