import React from 'react';
import './Work.css';
import { useTypewriterEffect } from '../useTypewriterEffect';  // Import the hook

function Work() {
  // Apply typewriter effect to titles
  const typewriterAmply = useTypewriterEffect('1. UI/UX Intern - Amply', 50);
  const typewriterAlphaEdison = useTypewriterEffect('2. Product Manager (Project) - Alpha Edison', 100);
  const typewriterBeachPoint = useTypewriterEffect('3. Software Engineer (Project) - Beach Point Capital', 200);
  const typewriterVisum = useTypewriterEffect('4. Co-Founder - Visum Contracting Group', 300);

  // Apply typewriter effect to bullet points
  const amplyBullet1 = useTypewriterEffect('Renovatied core website pages', 70);
  const amplyBullet2 = useTypewriterEffect('Refined software design elements', 70);
  const amplyBullet3 = useTypewriterEffect('Hotjar and Google Analytics', 70);


  const alphaEdisonBullet1 = useTypewriterEffect('Partnership through 5CVC', 100);
  const alphaEdisonBullet2 = useTypewriterEffect('Leading a team of 3 student engineers', 100);
  const alphaEdisonBullet3 = useTypewriterEffect('Cheerio.js scraping and NLP systhesis', 100);

  const beachPointBullet1 = useTypewriterEffect('NDA', 200);
  const beachPointBullet2 = useTypewriterEffect('Regex expressions, RESTful API, React', 200);

  const visumBullet1 = useTypewriterEffect('Manages full contract lifecycle for defense and medical RFPS', 200);
  const visumBullet2 = useTypewriterEffect('Partnered with Control Logistico under ITAR regulation', 200);
  const visumBullet3 = useTypewriterEffect('Dealers for North American Rescue, Hoplite Armor, etc.', 200);

  return (
    <div className="work-section">
      
      {/* Work 1 */}
      <div className="work-item">
        <div className="work-info">
          <p>
            <a 
              href="https://www.getamply.io/" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="work-link"
            >
              {typewriterAmply}
              <span className="link-icon">🔗</span>
            </a>
          </p>
          <hr className="divider" />
          <ul>
            <li>{amplyBullet1}</li>
            <li>{amplyBullet2}</li>
            <li>{amplyBullet3}</li>

          </ul>
          <div className="image-box">
          <video className="project-video" width="200" height="200" autoPlay loop muted>
            <source src="/assets/amply.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
        <div className="image-box">
          <img src="./assets/Ply.png" alt="Work 1 Alternate" />
        </div>
      </div>

      {/* Work 2 */}
      <div className="work-item">
        <div className="work-info">
          <p>
            <a 
              href="https://www.alphaedison.com/" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="work-link"
            >
              {typewriterAlphaEdison}
              <span className="link-icon">🔗</span>
            </a>
          </p>
          <hr className="divider" />
          <ul>
            <li>{alphaEdisonBullet1}</li>
            <li>{alphaEdisonBullet2}</li>
            <li>{alphaEdisonBullet3}</li>
          </ul>
        </div>
        <div className="image-box">
          <img src="./assets/AlphaEdison.webp" alt="Work 2" />
        </div>
        <div className="image-box">
          <img src="./assets/Poster.jpg" alt="Work 2 Alternate" />
        </div>
      </div>

      {/* Work 3 */}
      <div className="work-item">
        <div className="work-info">
          <p>
            <a 
              href="https://beachpoint.capital/" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="work-link"
            >
              {typewriterBeachPoint}
              <span className="link-icon">🔗</span>
            </a>
          </p>
          <hr className="divider" />
          <ul>
            <li>{beachPointBullet1}</li>
            <li>{beachPointBullet2}</li>
          </ul>
        </div>
        <div className="image-box">
          <img src="/assets/BPM.jpg" alt="Work 3" />
        </div>
      </div>

      {/* Work 4 */}
      <div className="work-item">
        <div className="work-info">
          <p>
            <a 
              href="https://visumcontractinggroup.com/" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="work-link"
            >
              {typewriterVisum}
              <span className="link-icon">🔗</span>
            </a>
          </p>
          <hr className="divider" />
          <ul>
            <li>{visumBullet1}</li>
            <li>{visumBullet2}</li>
            <li>{visumBullet3}</li>
          </ul>
        </div>
        <div className="image-box">
          <img src="./assets/images.png" alt="Work 4" />
        </div>
        <div className="image-box">
          <img src="./assets/NAR.png" alt="Work 4 Alternate" />
        </div>
      </div>
    </div>
  );
}

export default Work;
