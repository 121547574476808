// src/App.js
import React from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import IBMModel from './IBMModel';

function App() {
  return (
    <div style={{ position: 'relative', height: '100vh', width: '100vw' }}>
      {/* 3D Model Canvas */}
      <Canvas
        camera={{
          position: [50, 20, 0],   // Adjust the camera's position to be higher and slightly tilted
          fov: 19,                  // Field of view to zoom in/out
        }}
        style={{ position: 'absolute', top: 0, left: 0 }}
        gl={{ alpha: false }}       // Disable alpha (transparent background)
        onCreated={({ gl }) => {
          gl.setClearColor('#000000'); // Set the background to black
        }}
      >
        {/* Ambient light to softly illuminate the model */}
        <ambientLight intensity={0.8} />  {/* Increase ambient light for subtle fill lighting */}

        {/* Add directional light */}
        <directionalLight 
          intensity={1.5}   // Stronger light for illumination
          position={[10, 10, 10]}  // Light from above and slightly to the side
          castShadow={true}  // Enable shadows
        />

        {/* Point light for highlights on the model */}
        <pointLight 
          intensity={1} 
          position={[-5, 5, -5]} 
          distance={15} 
        />

        {/* SpotLight focused on the model */}
        <spotLight 
          position={[15, 20, 10]} 
          intensity={2}  // Strong light to highlight the model
          angle={0.3} 
          penumbra={1} 
          castShadow={true}  // Enable shadows
        />

        {/* Load the IBM model */}
        <IBMModel />

        {/* OrbitControls to control the camera, disable zoom and pan */}
        <OrbitControls 
          enableZoom={false}          // Disable zooming in/out
          enableRotate={false}         // Enable rotation to allow tilt backward (on load, no interaction)
          enablePan={false}           // Disable panning
          minPolarAngle={Math.PI / 2} // Restrict downward tilt
          maxPolarAngle={Math.PI / 1} // Restrict upward tilt to a backward tilt
          target={[0, 0, 0]}          // Focus on the center of the model
        />
      </Canvas>
    </div>
  );
}

export default App;
