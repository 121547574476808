// src/hooks/useTypewriterEffect.js
import { useState, useEffect } from 'react';

export const useTypewriterEffect = (text, speed = 50) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let currentText = '';
    let index = 0;

    const intervalId = setInterval(() => {
      if (index < text.length) {
        currentText += text[index];
        setDisplayedText(currentText);
        index++;
      } else {
        clearInterval(intervalId);
      }
    }, speed);

    return () => clearInterval(intervalId);  // Clean up the interval on unmount
  }, [text, speed]);

  return displayedText;
};
