import React from 'react';
import './Projects.css';
import { useTypewriterEffect } from '../useTypewriterEffect';  // Import the hook

function Projects() {
  // Apply typewriter effect to titles
  const typewriterWhatsHot = useTypewriterEffect('1. WhatsHot?', 50);
  const typewriter5CVC = useTypewriterEffect('2. 5C Venture Capital', 200);
  const typewriterComingSoon = useTypewriterEffect('3. Coming Real Soon', 300);
  const typewriterFutureUnderFire = useTypewriterEffect('4. FutureUnderFire', 400);

  // Apply typewriter effect to bullet points
  const whatsHotBullet1 = useTypewriterEffect('Reactive geo-centric map and chat', 70);
  const whatsHotBullet2 = useTypewriterEffect('Devleoped in Swift integrated with a FourSquare API', 70);
  const whatsHotBullet3 = useTypewriterEffect('500+ downloads and counting',70);

  const ventureBullet1 = useTypewriterEffect('Founder and co-president', 100);
  const ventureBullet2 = useTypewriterEffect('Interactive THREE.js website', 100);
  const ventureBullet3 = useTypewriterEffect('Multiple VC firm partnerships',100);

  const futureBullet1 = useTypewriterEffect('Co-Founder', 200);
  const futureBullet2 = useTypewriterEffect('Sustainable clothing club', 200);
  const futureBullet3 = useTypewriterEffect('Collection coordination and production', 200);

  return (
    <div className="projects-section">
      
      {/* Project 1 */}
      <div className="project">
        <div className="project-info">
          <p>
            <a 
              href="https://apps.apple.com/in/app/whatshot/id6504045918" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="project-link"
            >
              {typewriterWhatsHot}
              <span className="link-icon">🔗</span>
            </a>
          </p>
          <ul>
            <li>{whatsHotBullet1}</li>
            <li>{whatsHotBullet2}</li>
            <li>{whatsHotBullet3}</li>
          </ul>
        </div>
        <div className="image-box">
          <img className="project-image" src="/assets/WhatsHot.jpg" alt="Project 1" />
        </div>
      </div>

      {/* Project 2 */}
      <div className="project">
        <div className="project-info">
          <p>
            <a 
              href="https://www.5cventurecapital.com/" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="project-link"
            >
              {typewriter5CVC}
              <span className="link-icon">🔗</span>
            </a>
          </p>
          <ul>
            <li>{ventureBullet1}</li>
            <li>{ventureBullet2}</li>
            <li>{ventureBullet3}</li>
          </ul>
        </div>
        <div className="image-box">
          <video className="project-video" width="150" height="150" autoPlay loop muted>
            <source src="/assets/5CVC.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      {/* Project 3 */}
      <div className="project">
        <div className="project-info">
          <p>{typewriterComingSoon}</p>
          <ul>
            <li>{useTypewriterEffect('Co-founder', 50)}</li>
          </ul>
        </div>
        <div className="image-box">
          <img className="project-image" src="/assets/Sp.jpg" alt="Project 3" />
        </div>
        <div className="image-box">
          <img className="project-image" src="/assets/Tura.jpg" alt="Project 3 Alternate" />
        </div>
      </div>

      {/* Project 4 */}
      <div className="project">
        <div className="project-info">
          <p>{typewriterFutureUnderFire}</p>
          <ul>
            <li>{futureBullet1}</li>
            <li>{futureBullet2}</li>
            <li>{futureBullet3}</li>
          </ul>
        </div>
        <div className="image-box">
          <img className="project-image" src="/assets/FUF.jpeg" alt="Project 4" />
        </div>
        <div className="image-box">
          <img className="project-image" src="/assets/fu.jpeg" alt="Project 4 Alternate" />
        </div>
      </div>
    </div>
  );
}

export default Projects;
