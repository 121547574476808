// src/LivePortfolio.js
import React, { useState, useEffect, useRef } from 'react';
import Projects from './components/Projects';  // Import Projects section
import About from './components/About';        // Import About section
import Work from './components/Work';          // Import Work section
import './LivePortfolio.css';   // Import the new CSS file
import { useTypewriterEffect } from './useTypewriterEffect';  // Correctly import the hook

export default function LivePortfolio() {
  const [input, setInput] = useState('');
  const [history, setHistory] = useState(['']);
  const [currentComponent, setCurrentComponent] = useState(null);
  const textAreaRef = useRef(null); // Reference for the textarea

  const introText = 'Welcome to my portfolio, Type HELP for a list of commands.';
  const typewriterText = useTypewriterEffect(introText); // Typewriter effect

  const handleInput = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      processCommand(input);
      setInput('');
    }
  };

  const processCommand = (command) => {
    let newHistory = [];

    switch (command.toUpperCase()) {
      case 'HELP':
        newHistory.push('> ' + command);
        newHistory.push('Available Commands:');
        newHistory.push('ABOUT - Information about me');
        newHistory.push('PROJECTS - List of projects');
        newHistory.push('WORK - Professional experience');
        newHistory.push('CONTACT - Hit me!');
        setCurrentComponent(null);
        break;
      case 'ABOUT':
        newHistory.push('> ' + command);
        newHistory.push('Type HELP for a list of commands.');
        setCurrentComponent(<About />);
        break;
      case 'PROJECTS':
        newHistory.push('> ' + command);
        newHistory.push('Type HELP for a list of commands.');
        setCurrentComponent(<Projects />);
        break;
      case 'WORK':
        newHistory.push('> ' + command);
        newHistory.push('Type HELP for a list of commands.');
        setCurrentComponent(<Work />);
        break;
      case 'CONTACT':
        newHistory.push('> ' + command);
        newHistory.push('Redirecting to LinkedIn...');
        setTimeout(() => {
          window.location.href = 'https://www.linkedin.com/in/sam-freeman-6b462b254/'; // Replace with your LinkedIn URL
        }, 1500); // Delay for effect
        setCurrentComponent(null);
        break;
      default:
        newHistory = [...history, `> ${command}`];
        newHistory.push(`'${command}' is not recognized. Type HELP for available commands.`);
        setCurrentComponent(null);
    }

    setHistory(newHistory);
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [history]);

  return (
    <div style={{
      height: '215px', 
      width: '310px', 
      backgroundColor: 'black',
      color: '#00FF00',
      fontFamily: 'monospace',
      padding: '20px',
      overflowY: 'auto',
    }}>
      <div>{typewriterText}</div>

      {history.map((line, index) => (
        <div key={index} style={{ color: '#00FF00' }}>
          {line}
        </div>
      ))}
      
      <textarea
        ref={textAreaRef} 
        style={{
          width: '100%',
          backgroundColor: 'black',
          color: 'green',
          border: 'none',
          outline: 'none',
          fontFamily: 'monospace',
          fontSize: '16px',
          resize: 'none',
        }}
        autoFocus
        rows="1"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={handleInput}
      />
      {currentComponent && <div style={{ marginTop: '20px' }}>{currentComponent}</div>}
    </div>
  );
}
