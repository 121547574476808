import React from 'react';
import './About.css'; // Separate CSS file to style the About section
import { useTypewriterEffect } from '../useTypewriterEffect';  // Import the hook

function About() {
  const sentence1 = useTypewriterEffect("I'm Sam, a junior at Pitzer College currently studying Cognitive Science and Economics in Claremont, CA.", 20);
  const sentence2 = useTypewriterEffect("I built this website to pay homeage to a youthful affinity for old school technology and retro video games. This exact affinty molded me to who I am today, someone with a deep passion for developing real-word impactful products. Along with the virtual side, sports have also played a massive role in my life. From Basketball, Hockey, and Brazilian Jiu-Jitsu, I've always loved athletics and still participate in them today.", 10);
  const sentence3 = useTypewriterEffect("As I got older, I developed an interest in anything related to defense. Below, you're looking at a flashback to my first little bird ride in South Carolina. It was easily one of the greatest moments of my life. And yes, the tubes are white phos :o", 30);
  const sentence4 = useTypewriterEffect("Oh yeah, and I absolutely love anything related design/fashion. Believe it or not, sewing is a bit of a hobby of mine, although admittedly I'm pretty horrible at it.", 50);

  return (
    <div className="about-section">
      <p className="about-header">{sentence1}</p>

      <div className="image-box">
        <img className="about-image" src="./assets/CurrentMe.jpeg" alt="Sam Freeman" />
      </div>

      <p className="about-text">{sentence2}</p>

      <div className="image-box">
        <img className="about-image" src="./assets/Hockey.jpg" alt="Gaming/Sports" />
      </div>

      <p className="about-text">{sentence3}</p>

      <div className="image-box">
        <img className="about-image" src="./assets/Chopper.JPG" alt="Chopper Ride" />
      </div>

      <p className="about-text">{sentence4}</p>

      <div className="image-box">
        <img className="about-image" src="./assets/Sew2.jpeg" alt="Sewing Hobby" />
      </div>
    </div>
  );
}

export default About;
