import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Import the CSS
import App from './App';
import reportWebVitals from './reportWebVitals';

// Ensure the app waits for DOM to load completely
const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  console.error('Failed to find the root element');
}

// Optional: Measure performance
reportWebVitals(console.log);
